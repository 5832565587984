@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Manrope:wght@200..800&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oswald:wght@638&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&family=Prosto+One&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: black;
}
::-webkit-scrollbar-thumb {
  background: #39ABFE;
  border-radius: 20px;
  
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.roboto {
  font-family: "Roboto", sans-serif;
}
.open-sans{
    font-family: "Open Sans", sans-serif;
}
@font-face {
  font-family: 'pepsi';
  src: url('../src/10100.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.pepsi {
  font-family: 'pepsi', sans-serif;
}

.header-container {
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  z-index: 1000;
}
.button{
  display: inline-flex;
  padding: 10px 22px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 70px;
  border: 1px solid #000;
}
.headerbg{
  border-radius: 60px;
  background: linear-gradient(90deg, #34F3F3 0%, #39ABFE 100%);
  backdrop-filter: blur(2px);
}

.Dropdown-control {
  cursor: pointer;
  font-style: normal!important;
  font-size: 16px!important;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  background-color: transparent!important;
  border: none!important;
  color: #000!important;
  text-transform: uppercase!important;
  padding-right:25px!important;
  
}
.Dropdown-menu {
  font-style: normal;
  font-size: 16px!important;
  max-height: 300px!important;
  
}
.Dropdown-arrow {
  border-color: #000 transparent transparent!important;
  margin-top: 3px;
 
}
.is-open .Dropdown-arrow {
  border-color: transparent transparent #000!important;
}

.buttonNew{
  display: inline-flex;
  padding: 13px 27px;
  justify-content: center;
  align-items: center;
  gap: 21.812px;
  border-radius: 61.074px;
  background: #34F3F3;
}

.gradient-box {
  border-radius: 30px;
  border: 1px solid #34F3F3;
}